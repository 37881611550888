import React from "react";
import { Helmet } from "react-helmet";
import { shiftColor } from "./shiftColor";

export const UniversityTheme = (props) => {
	if (!props?.basicConfig?.primaryColor) {
		return null;
	}

	// Change 'let' to 'const' afterwards
	let { primaryColor, primaryFontColor, secondaryColor, fontFamily } =
		props?.basicConfig;

	// We can delete below commented lines afterwards. They'll make testing with various colour themes easier. We can also add more

	// Stony Brook
	// primaryColor = "#4B4B4B";
	// secondaryColor = "#990000";

	// USF
	// primaryColor = "#006747";
	// secondaryColor = "#CFAE38";

	// Auburn
	// primaryColor = "#00244d";
	// secondaryColor = "#de7008";

	// fontFamily = "Comic Sans MS"
	// fontFamily = "Haffer XH"

	const LIGHT_SHIFT = 40;

	return (
		<Helmet>
			<style type="text/css" data-type="UniversityTheme">
				{`:root {
                    --theme-primary: ${primaryColor};
                    --theme-primary-light: ${shiftColor(
											primaryColor,
											LIGHT_SHIFT,
										)};
                    --theme-primary-pale: ${shiftColor(
											primaryColor,
											LIGHT_SHIFT,
										)}40;
                    --theme-secondary: ${secondaryColor};
                    --theme-secondary-light: ${shiftColor(
											secondaryColor,
											LIGHT_SHIFT,
										)};
                    --theme-secondary-pale: ${shiftColor(
											secondaryColor,
											LIGHT_SHIFT,
										)}40;
                    --theme-dark-font-color: ${primaryFontColor || "#05082b"};
                    --theme-light-font-color: #FFFFFF;

                    --primary-forest-400: ${shiftColor(
											primaryColor,
											LIGHT_SHIFT,
										)};
                    --primary-forest-600: ${primaryColor};

                    --primary-green-400: ${shiftColor(
											secondaryColor,
											LIGHT_SHIFT,
										)};

                    --primary-neutral-nightshade-600: ${
											primaryFontColor || "#32354f"
										};
                    --primary-neutral-nightshade-800: ${
											primaryFontColor || "#05082b"
										};

                    --secondary-purple-100: ${shiftColor(
											secondaryColor,
											LIGHT_SHIFT,
										)}40;
                    --secondary-purple-200: ${shiftColor(
											secondaryColor,
											LIGHT_SHIFT,
										)};
                    --secondary-purple-300: ${secondaryColor};

                    /* Below set of rules may have to be removed! */
                    --secondary-pencil-100: ${shiftColor(
											secondaryColor,
											LIGHT_SHIFT,
										)}40;
                    --secondary-pencil-200: ${shiftColor(
											secondaryColor,
											LIGHT_SHIFT,
										)};
                    --secondary-blue-300: ${primaryColor};

                    --theme-ff: ${fontFamily || "Haffer XH"}, sans-serif;
                    --ff-haffer-xh: ${fontFamily || "Haffer XH"}, sans-serif;
                    --desktop-h0-ff: ${fontFamily || "Haffer XH"}, sans-serif;
                    --desktop-h1-ff: ${fontFamily || "Haffer XH"}, sans-serif;
                    --desktop-h2-ff: ${fontFamily || "Haffer XH"}, sans-serif;
                    --desktop-h3-ff: ${fontFamily || "Haffer XH"}, sans-serif;
                    --desktop-h4-ff: ${fontFamily || "Haffer XH"}, sans-serif;
                    --desktop-h5-ff: ${fontFamily || "Haffer XH"}, sans-serif;
                    --desktop-h6-ff: ${fontFamily || "Haffer XH"}, sans-serif;
                    --desktop-body-l-regular-ff: ${
											fontFamily || "Haffer XH"
										}, sans-serif;
                    --desktop-body-l-bold-ff: ${
											fontFamily || "Haffer XH"
										}, sans-serif;
                    --desktop-body-m-regular-ff: ${
											fontFamily || "Haffer XH"
										}, sans-serif;
                    --desktop-body-m-bold-ff: ${
											fontFamily || "Haffer XH"
										}, sans-serif;
                    --desktop-body-s-regular-ff: ${
											fontFamily || "Haffer XH"
										}, sans-serif;
                    --desktop-body-s-bold-ff: ${
											fontFamily || "Haffer XH"
										}, sans-serif;
                    --desktop-label-m-ff: ${
											fontFamily || "Haffer XH"
										}, sans-serif;
                    --mobile-h0-ff: ${fontFamily || "Haffer XH"}, sans-serif;
                    --mobile-h1-ff: ${fontFamily || "Haffer XH"}, sans-serif;
                    --mobile-h2-ff: ${fontFamily || "Haffer XH"}, sans-serif;
                    --mobile-h3-ff: ${fontFamily || "Haffer XH"}, sans-serif;
                    --mobile-h4-ff: ${fontFamily || "Haffer XH"}, sans-serif;
                    --mobile-h5-ff: ${fontFamily || "Haffer XH"}, sans-serif;
                    --mobile-h6-ff: ${fontFamily || "Haffer XH"}, sans-serif;
                    --mobile-body-l-regular-ff: ${
											fontFamily || "Haffer XH"
										}, sans-serif;
                    --mobile-body-l-bold-ff: ${
											fontFamily || "Haffer XH"
										}, sans-serif;
                    --mobile-body-m-regular-ff: ${
											fontFamily || "Haffer XH"
										}, sans-serif;
                    --mobile-body-m-bold-ff: ${
											fontFamily || "Haffer XH"
										}, sans-serif;
                    --mobile-body-s-regular-ff: ${
											fontFamily || "Haffer XH"
										}, sans-serif;
                    --mobile-body-s-bold-ff: ${
											fontFamily || "Haffer XH"
										}, sans-serif;
                    --mobile-label-l-ff: ${
											fontFamily || "Haffer XH"
										}, sans-serif;
                    --mobile-label-m-ff: ${
											fontFamily || "Haffer XH"
										}, sans-serif;
                }`}
			</style>
		</Helmet>
	);
};
